import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'notifications/';
  }

  listByCriteria(targetType?: string, targetId?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (targetType) { params['targetType'] = targetType.toString(); }
    if (targetId) { params['targetId'] = targetId.toString(); }

    return this.http.get(this.getUrl() + 'minimal', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listEvents(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/events`)
      .pipe(map(res => {
        return res;
      }));
  }

  listAttachments(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/attachments`)
      .pipe(map(res => {
        return res;
      }));
  }

  sendEmail(data: any) {
    return this.http.post(this.getUrl() + `email`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  sendCollectionsEmail(data: any) {
    return this.http.post(this.getUrl() + `email/collections-payment`, data)
      .pipe(map(res => {
        return res;
      }));

  }

  sendFundingsEmail(data: any) {
    return this.http.post(this.getUrl() + `email/fundings-page`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  sendClientsEmail(data: any) {
    return this.http.post(this.getUrl() + `email/clients-page`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  sendSms(data: any) {
    return this.http.post(this.getUrl() + `sms`, data)
      .pipe(map(res => {
        return res;
      }));
  }
}
