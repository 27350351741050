<div class="content-wrapper">
  <div class="clearfix heading">
    <h2>{{ syndicator?.name }} Wallets</h2>

    <div class="action-buttons" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL']">
      <button mat-raised-button color="accent" (click)="addWallet()">ADD WALLET</button>
    </div>
  </div>

  <div class="body scrollable pt-3">
    <table mat-table [dataSource]="walletTable" class="clickable">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
      <ng-container matColumnDef="whiteLabel">
        <th mat-header-cell *matHeaderCellDef>White Label</th>
        <td mat-cell *matCellDef="let row"> {{row.whiteLabelName}} </td>
      </ng-container>
      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>Balance</th>
        <td mat-cell *matCellDef="let row"> {{(row.balance || 0) | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="pendingCredits">
        <th mat-header-cell *matHeaderCellDef>Pending Credits</th>
        <td mat-cell *matCellDef="let row"> {{(row.pendingCredits || 0) | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="pendingDebits">
        <th mat-header-cell *matHeaderCellDef>Pending Debits</th>
        <td mat-cell *matCellDef="let row"> {{(row.pendingDebits || 0) | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date Created</th>
        <td mat-cell *matCellDef="let row" class="nowrap"> {{row.createdDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef>Updated Date</th>
        <td mat-cell *matCellDef="let row"> {{row.updatedDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>Created By</th>
        <td mat-cell *matCellDef="let row"> {{row.createdBy | fullName}} </td>
      </ng-container>
      <ng-container matColumnDef="updatedBy">
        <th mat-header-cell *matHeaderCellDef>Updated By</th>
        <td mat-cell *matCellDef="let row"> {{row.updatedBy | fullName}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="viewWallet(row)"></tr>
    </table>
    <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="walletTable?.data?.length === 0"></oiq-loading-message>
  </div>
</div>
