<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Create New Wallet</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" *ngIf="data.whiteLabels?.length > 0; else noWhiteLabels">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <mat-label>White Label</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="whiteLabelId">
              <mat-option *ngFor="let row of data.whiteLabels" [value]="row.id">
                {{ row.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="errorMessage">
          <p class="error-text col">
            {{errorMessage}}
          </p>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="onCancel()" oiq-loading [spinner]="false">Cancel</button>
      <button mat-raised-button color="accent" type="submit" oiq-loading [buttonDisabled]="!form.valid" (click)="onSubmit()" cdkFocusInitial>
        Add
      </button>
    </div>
  </form>
  <ng-template #noWhiteLabels>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <p>There aren’t any WLs assigned to this Syndicator. Click <a class="link text-link und" [routerLink]="['syndicators', data.syndicatorId, 'settings']" (click)="dialogRef.close()">here</a> to update.</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
