import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CurrencyPipe, DatePipe } from '@angular/common';

import { merge } from "rxjs";
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { SyndicationFundDialogComponent } from '@shared/syndication-fund-dialog/syndication-fund-dialog.component';

import { Subscription } from 'rxjs';

import { ApplicationService, FundingService, SyndicatorService } from '@core/services';
import { AuthService } from '@core/authentication/auth.service';
import { AddSyndicationsDialogComponent } from '@shared/syndication/add-syndications-dialog/add-syndications-dialog.component';
import { AddSyndicationOffersDialogComponent } from '@shared/syndication/add-syndication-offers-dialog/add-syndication-offers-dialog.component';

@Component({
  selector: 'oiq-syndication-offers',
  templateUrl: './syndication-offers.component.html',
  styleUrls: ['./syndication-offers.component.scss']
})
export class SyndicationOffersComponent implements OnInit {
  private applicationSubscription: Subscription;
  private fundingSubscription: Subscription;


  application: any;
  funding: any;

  rows: Array<any> = [];
  datasource: any;

  total: number;
  currentPage: number;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  columns = [
    'name',
    'type',
    'status',
    'fundedAmount',
    'minimumPercent',
    'maximumPercent',
    'acceptedAmount',
    'acceptedPercent',
    'fee',
    'commission',
    'commissionAmount',
    'maxTotalReceived',
    'maxPayback',
    'term',
    'details',
    'actions'
  ];

  tenantId: number;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private applicationService: ApplicationService,
    private syndicatorService: SyndicatorService,
    private authService: AuthService,
    private fundingService: FundingService
  ) {
    this.fundingService.funding$.subscribe(data => {
      this.funding = data
    })
  }

  ngOnInit() {
    this.tenantId = this.authService.getUser().tenantId;

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;
      if (this.application) {
        this.getSyndicationOffers();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();;
          this.getSyndicationOffers();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
  }

  openSyndicationOfferDialog() {
    const dialog = this.dialog.open(AddSyndicationOffersDialogComponent, {
      width: '90%',
      data: {
        application: this.application,
        tenantId: this.tenantId,
        funding: this.funding,
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.getSyndicationOffers();
      }
    });
  }

  openEditSyndicationOfferDialog(row) {
    let dialog;

    if (row.type === 'Manual') {
      dialog = this.dialog.open(AddSyndicationsDialogComponent, {
        width: '90%',
        data: {
          syndications: [row],
          application: this.application,
          funding: this.funding,
          isEdit: true
        }
      });
    } else {
      dialog = this.dialog.open(AddSyndicationOffersDialogComponent, {
        width: '90%',
        data: {
          syndicationOffers: [row],
          application: this.application,
          funding: this.funding,
          isEdit: true
        }
      });
    }

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.getSyndicationOffers();
      }
    });
  }

  openFundingSyndicationDialog() {
    const dialog = this.dialog.open(AddSyndicationsDialogComponent, {
      width: '90%',
      data: {
        application: this.application,
        tenantId: this.tenantId,
        funding: this.funding
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.getSyndicationOffers();
      }
    });
  }

  openPaymentDialog(row) {
    const principalSyndicated = row.acceptedAmountSyndicated;

    const commissionPercent = this.application.appliedFundingOffer.commissionPercent;

    const amountToBePaid = principalSyndicated + (principalSyndicated * (commissionPercent / 100));

    const amount = this.currencyPipe.transform(amountToBePaid);
    const dateCreated = this.datePipe.transform(row.createdDate);
    const dateUpdated = this.datePipe.transform(row.updatedDate);

    const dialog = this.dialog.open(SyndicationFundDialogComponent, {
      width: '800px',
      data: {
        offer: row,
        application: this.application
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.syndicatorService.updateBilling(row.syndicator.id, result.data)
          .subscribe((r: any) => {

            const transmissionData = {
              transmissionMethod: result.data.fundingTransmissionMethod,
              transmissionProcessor: result.data.fundingTransmissionProcessor,
              processorAccountName: result.data.processorAccountName
            };

            this.applicationService.payOffer(
              this.application.id,
              row.id,
              transmissionData
            ).subscribe((r: any) => {
              this.getSyndicationOffers();
            });
          });
      }
    });
  }

  resendOfferEmail(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = 'Are you sure you want to resend the syndication offer email?';
    dialogConfig.width = '650px';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.syndicatorService.resendSyndicationOfferEmail(this.application.id, row.id).subscribe((data) => {

        });
      }
    });
  }

  getSyndicationOffers() {
    this.applicationService.listSyndicationOffers(
      this.funding.applicationId,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir
    ).subscribe((r: any) => {
      this.rows = r.content;

      this.total = r.totalElements;
      this.datasource = new MatTableDataSource(this.rows);
      this.datasource.sort = this.sort;
    });
  }

  deleteOffer(row) {
    row.deleting = true;
    let message;

    if (row.status === 'Paid') {
      if (row?.syndicatorWallet) {
        message = 'When deleted the syndicated amount will be credited back to the syndicator\'s wallet.';
      } else {
        message = 'When deleted the payment needs to be manually sent back to the syndicator!';
      }
    } else {
      message = 'Are you sure you want to delete this syndication offer?';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: message
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.syndicatorService.deleteSyndicationOffer(this.application.id, row.id)
          .subscribe((r: any) => {
            row.deleting = false;
            this.getSyndicationOffers();
          });
      } else {
        row.deleting = false;
      }
    });
  }

  cancelDeleteOffer(row) {
    row.deleting = false;
  }

   isNullOrUndefined(value) {
    return value === null || value === undefined;
  }
}
