<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Payments</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-6">
        <mat-label>Funding Transmission Method *</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="fundingTransmissionMethod" (selectionChange)="onFundingTransmissionMethodChanged($event)" required>
            <mat-option *ngFor="let row of transmissionMethods" [value]="row.name">
              {{ row.name | status }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="fundingTransmissionMethod === 'ACH' || fundingTransmissionMethod === 'Syndicator_Wallet'">
        <mat-label>Funding Transmission Processor *</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="fundingTransmissionProcessor" (selectionChange)="onFundingTransmissionProcessorChanged($event)" [required]="fundingTransmissionProcessorRequired">
            <mat-option *ngFor="let row of transmissionProcessors" [value]="row" (onSelectionChange)="selectProcessor($event, row.processorAccountName)">
              <span *ngIf="row.automated">{{ row.processor }}{{ row.processorAccountName ? ' - ' + row.processorAccountName : '' }}</span>
              <span *ngIf="!row.automated">{{ row.processor }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row ps-3 pe-3 pt-3">
        <oiq-bank-selection
        [type]="'syndicator'"
        [id]="data.offer.syndicator.id"
        [fundingBankId]="data.offer.syndicator.fundingBankId"
        (onFundingBankSelected)="onFundingBankSelected($event)"
        [fundingBankVisible]="fundingBankVisible"
        [paymentBankVisible]="false" *ngIf="data.offer.syndicator">
      </oiq-bank-selection>
    </div>

    <div class="row justify-content-center">
      <div class="col text-center">
        <p> <strong>Offer Created:</strong> {{ data.offer.createdDate | date }}<br> </p>
        <p><strong>Offer Accepted:</strong> {{ data.offer.updatedDate | date }}<br></p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col text-center">
        <h4 *ngIf="!isCalculatingAmountBasedOnSyndicationCalculationLogic" >
          Are you sure you want to {{ fundingTransmissionMethod === 'ACH' && (fundingTransmissionProcessor === 'Manual' || fundingTransmissionProcessor === 'Other') ? 'add' : 'initiate' }} this Syndicator's payment of <strong>{{ calculatedAmountBasedOnSyndicationCalculationLogic | currency }}</strong>?
        </h4>
        <div class="col-sm-12 text-center" *ngIf="isCalculatingAmountBasedOnSyndicationCalculationLogic" [class.loading-records]="isCalculatingAmountBasedOnSyndicationCalculationLogic">
          <strong> Calculating amount </strong>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-center">
    <button mat-stroked-button mat-dialog-close >Cancel</button>
    <button mat-raised-button color="accent" type="button"  [class.loading]="isCalculatingAmountBasedOnSyndicationCalculationLogic" [disabled]='isCalculatingAmountBasedOnSyndicationCalculationLogic'
     (click)="confirm()">
      {{ fundingTransmissionMethod !== 'ACH' || (fundingTransmissionMethod === 'ACH' && (fundingTransmissionProcessor === 'Manual' || fundingTransmissionProcessor === 'Other')) ? 'ADD PAYMENT' : 'INITIATE PAYMENT' }}
    </button>
  </div>
</div>
