<div class="position">
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ data?.prepayment?.id ? 'Edit' : 'Add' }} Prepay</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row" *ngIf="data.prepayment.factorRate">
        <div class="col-sm-3">
          <mat-label>Term</mat-label>
          <div class="field-value">{{ data.prepayment.term }}</div>
        </div>
        <div class="col-sm-3">
          <mat-label>Factor Rate</mat-label>
          <div class="field-value">{{ data.prepayment.factorRate }}</div>
        </div>
        <div class="col-sm-3">
          <mat-label>Point Built In</mat-label>
          <div class="field-value">{{ data.prepayment.commissionPercent | number : '1.2-2' }}%</div>
        </div>
        <div class="col-sm-3">
          <mat-label>Total Repayment</mat-label>
          <div class="field-value">{{ data.prepayment.paybackAmount | currency }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <mat-label>Buy Rate</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="buyRate" min="0" type="number" step="0.01" autocomplete="off" numberFormat [decimalsLimit]="4">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>Start Day</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="startDay" min="0" type="number" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>End Day</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="endDay" min="0" type="number" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>Factor Rate</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="factorRate" type="number" min="0" step="0.01" numberFormat [decimalsLimit]="4" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button mat-dialog-close color="accent" type="submit" (click)="save()" [disabled]="!form.valid"
              cdkFocusInitial>
        Save
      </button>
    </div>
  </form>
</div>
