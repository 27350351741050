<div class="scorecard-dialog-wrapper">
    <div *ngIf="data">
      <div class="mat-dialog-title-wrapper">
        <h3>SCORECARD RESULT</h3>
      </div>
      <hr>
      <div mat-dialog-content class="d-flex">
        <h4 class="fw-bold"> Weighted score: </h4>
        <p class="fw-bold px-2">{{data.WeightedScore}}</p>
      </div>
      <div mat-dialog-content class="row">
        <h4 class="fw-bold"> Score results: </h4>
        <div *ngFor="let scoreResult of data.ScoreResults" class="fw-bold score-results">
          <br>
          <div class="results">
            <ul class="list-unstyled">
              <li><b>Ruleset name:</b> {{scoreResult.RuleSetName}} </li>
              <li><b>Score:</b> {{scoreResult.Score}}</li>
              <li><b>Weight:</b> {{scoreResult.Weight}}</li>
            </ul>
            <hr>
          </div>
        </div>
      </div>

      <div mat-dialog-content class="row">
        <div *ngFor="let decisionResult of data.DecisionResults" class="fw-bold decision-ruleset">
          <div class="decision-rule">
            <h4 class="fw-bold"> Decision results: </h4>
            <div class="rule">
              <h5 class="fw-bold decision-rule-label"> Ruleset: </h5>
              <p class="fw-bold decision-rule-value">{{decisionResult.RuleSetName}}</p>
            </div>
          </div>

          <div class="offers">
            <h4 class="fw-bold offers-label"> Offers: </h4>
            <p class="fw-bold" *ngIf="decisionResult.Offers == null">No offer</p>
            <br>
            <div *ngIf="decisionResult.Offers !== null">
              <div *ngFor="let offer of decisionResult.Offers; let i = index">
                <ul class="list-unstyled">
                 <li> <b>Buy Rate:</b> {{offer.BuyRate}} </li>
                 <li><b>CommissionPercent:</b> {{offer.CommissionPercent}}</li>
                 <li><b>PercentOfGross:</b> {{offer.PercentOfGross}}</li>
                 <li><b>TermLength:</b> {{offer.TermLength}}</li>
                </ul>
                <hr>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class="mx-auto" 
          mat-raised-button 
          mat-dialog-close 
          color="accent" 
          cdkFocusInitial 
          type="submit"
          (click)="close($event)"
        >
          Ok
        </button>
      </div>
    </div>
    <div *ngIf="!data">
      <div mat-dialog-content class="row">
        <h4 class="fw-bold col-md-12 pl-0"> Choose application: </h4>

        <mat-select class="form-control col-md-12" (selectionChange)="onSelectionChange($event)">
          <mat-option *ngFor="let application of applications" [value]="application.id">
            {{ application.id}} : {{application.status}}
          </mat-option>
        </mat-select>
      </div>
    
      <div mat-dialog-actions>
        <button class="mx-auto" 
          mat-raised-button 
          mat-dialog-close 
          color="accent" 
          cdkFocusInitial 
          type="submit"
          [disabled]="!applicationId"
          (click)="runScoreCard()"
        >
          Run
        </button>
      </div>
    </div>
    
</div>