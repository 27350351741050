import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numberFormat]'
})
export class NumberFormatDirective {
  @Input() decimalsLimit: number;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;

    // Replace commas and dots with dots for uniform decimal handling
    let formattedValue = inputValue.replace(/[,.]/g, '.');

    // Ensure the value is non-negative
    if (formattedValue.startsWith('-')) {
      formattedValue = formattedValue.substring(1);
    }

    // Limit the number of decimals only if the input has been sent with a limit
    const parts = formattedValue.split('.');
    if (this.decimalsLimit && parts.length > 1 && parts[1].length > this.decimalsLimit) {
      parts[1] = parts[1].substring(0, this.decimalsLimit);
      formattedValue = parts.join('.');
    }

    // Check if the value has actually changed
    if (formattedValue !== inputValue) {
      // Update the input value
      inputElement.value = formattedValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
