<div class="content">
  <div *ngIf="!loading" class="content-wrapper">
    <div class="scorecard-header">
      <h1 class="header-title">SCORECARD</h1>

      <button  class="btn btn-danger btn-sm text-uppercase"
        (click)="deleteAllRulesForTenant()">Delete all rulesets
      </button>
    </div>
    <div class="score-card bg-white p-4 my-3" [formGroup]="ruleSet" *ngFor="let ruleSet of form['controls']; let ruleSetIndex = index">

      <div class="header d-flex justify-content-between pb-4 mb-3 border-bottom">
        <h2 class="title d-flex align-items-center">
          <span class="index mx-2">{{ ruleSetIndex + 1 }}</span>
          Rule Set
        </h2>

        <div class="actions d-flex">
          <mat-slide-toggle color="primary" class="button-v2 bg-outline mr-3 my-2" formControlName="useSet">Use Set</mat-slide-toggle>
          <button mat-stroked-button color="none" (click)="removeAllRules(ruleSetIndex)"
            class="button-v2 shadow-sm bg-outline border mr-3 mx-2">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="url(#paint0_linear_948_5242)" />
              <defs>
                <linearGradient id="paint0_linear_948_5242" x1="0.166626" y1="0.166687" x2="12.3422" y2="0.730669"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#02244E" />
                  <stop offset="1" stop-color="#3F6BA1" />
                </linearGradient>
              </defs>
            </svg>
            Clear all rules</button>
          <button mat-raised-button color="primary" (click)="removeRuleSet(index, ruleSet.value._id)"
            class="button-v2 shadow bg-orange text-white mx-2">
            Delete</button>
        </div>
      </div> <!-- end .header -->

      <div class="controls">
        <div class="meta my-4">
          <div class="row">
            <div class="col-sm-12 col-md-3 form-group d-flex flex-column">
              <mat-label>Name:</mat-label>
              <mat-form-field appearance="outline" class="table-select">
                <input formControlName="ruleSetName" matInput type="text">
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 form-group d-flex flex-column">
              <mat-label>Description:</mat-label>
              <mat-form-field appearance="outline" class="table-select">
                <input formControlName="ruleSetDescription" matInput type="text">
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-2 form-group d-flex flex-column">
              <mat-label>Type:</mat-label>
              <mat-form-field appearance="outline" class="table-select">
                <mat-select placeholder="Select Type" formControlName="ruleSetType" (selectionChange)="ruleSetTypeChange($event)">
                  <mat-option value="score">Score</mat-option>
                  <mat-option value="decision">Decision</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-1 form-group d-flex flex-column">
              <mat-label>Weight:</mat-label>
              <mat-form-field appearance="outline" class="table-select">
                <input formControlName="scoreWeight" matInput type="number" step=".1">
              </mat-form-field>
            </div>

          </div>
        </div>

        <div class="conditions list">
          <p *ngIf="ruleSet.get('rules')['controls'].length" class="font-weight-semibold mb-1">Rules:</p>
          <div *ngFor="let rule of ruleSet.get('rules')['controls']; let ruleIndex = index" class="d-flex w-100 align-items-center">
            <div class="condition w-100 my-1" [formGroup]="rule">
              <div class="conditional">
                <!-- DB OR SCORE CONDITIONAL -->
                <ng-container *ngIf="rule.get('conditional.arguments')" [formGroup]="rule.get('conditional')">
                  <div class="mx-2  px-2">
                    <mat-form-field appearance="" class="">
                      <mat-label>Parameter</mat-label>
                      <mat-select (selectionChange)="onParameterSelect($event, ruleSetIndex, ruleIndex)" formControlName="conditional">
                        <mat-option [value]="parameter.value"
                          *ngFor="let parameter of parameters">
                          {{ parameter.name  }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="mx-2  px-2">
                    <mat-form-field appearance="" class="">
                      <mat-label>Operator</mat-label>
                      <mat-select class="text-center" formControlName="operator"
                        (selectionChange)="setArguments(rule, $event.value)">
                        <mat-option [value]="operator.value" *ngFor="let operator of filteredOperators[ruleSetIndex + '_' + ruleIndex]">
                          {{ operator.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <ng-container formArrayName="arguments">
                    <div class="mx-2  px-2 my-2"
                      *ngFor="let argument of rule.get('conditional.arguments')['controls']; let argumentIndex = index">
                      <mat-form-field appearance="outline" [formGroup]="argument">
                        <input type="{{inputType}}" matInput formControlName="argValue">
                      </mat-form-field>
                    </div>
                  </ng-container>
                </ng-container>

                <!-- LOGIC CONDITIONAL -->
                <ng-container *ngIf="!rule.get('conditional.arguments')" [formGroup]="rule.get('conditional')">
                  <div class="mx-2 px-2">
                    <mat-form-field appearance="" class="">
                      <mat-label>Logic Parameter</mat-label>
                      <mat-select formControlName="logicOperator">
                        <mat-option [value]="parameter.value" *ngFor="let parameter of logicalParameters">
                          {{ parameter.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <ng-container formArrayName="conditional">
                    <div *ngFor="let condition of rule.get('conditional.conditional')['controls']; let conditionIndex = index" [formGroupName]="conditionIndex">
                      <div class="mx-2 px-2">
                        <mat-form-field appearance="" class="">
                          <mat-label>Parameter</mat-label>
                          <mat-select (selectionChange)="onParameterSelect($event, ruleSetIndex, ruleIndex)" formControlName="conditional">
                            <mat-option [value]="parameter.value" *ngFor="let parameter of parameters">
                              {{ parameter.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="mx-2 px-2">
                        <mat-form-field appearance="" class="">
                          <mat-label>Operator</mat-label>
                          <mat-select class="text-center" formControlName="operator" (selectionChange)="setArguments(rule, $event.value, conditionIndex)">
                            <mat-option [value]="operator.value" *ngFor="let operator of filteredOperators[ruleSetIndex + '_' + ruleIndex]">
                              {{ operator.value }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="mx-2 px-2 my-2" *ngFor="let argument of condition.get('arguments')['controls']; let argumentIndex = index">
                        <mat-form-field appearance="outline" [formGroup]="argument">
                          <input type="{{inputType}}" matInput formControlName="argValue">
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container formGroupName="result">
                  <div class="px-2 my-2 scr">
                    <mat-form-field appearance="outline" class="table-select">
                      <mat-label class="mr-2">Score:</mat-label>
                      <input type="number" matInput formControlName="score">
                    </mat-form-field>
                  </div>
                </ng-container>
                <div>
                  <button
                    *ngIf="checkIfDecision(form.controls[ruleSetIndex])"
                    class="btn align-self-center btn-primary btn-sm mx-2 mt-2" 
                    (click)="addOffer(rule.get('result'), ruleIndex)"
                  >
                    Add offer
                  </button>
                </div>
              </div>
              <!-- RESULT SECTION -->

                <!-- OFFERS SECTION -->
                <ng-container formGroupName="result">
                  <div  *ngIf="rule.get('result.offers').length > 0 "  class="offers" formArrayName="offers">
                    <div class="offer-container" *ngFor="let offer of rule.get('result.offers').controls; let i=index" [formGroupName]="i">
                      <div class="offer-header">
                        <mat-label>Offer {{ i + 1 }}:</mat-label>
                        <div>
                          <span 
                            *ngIf="rule.get('result.offers').length > 0 " 
                            class="add-field" 
                            (click)="addOfferField(offer)"
                          >
                          New field +
                          </span>
                        </div>
                      </div>
                      <div formArrayName="fields">
                        <div class="field-container">
                          <div *ngFor="let field of offer.get('fields').controls; let j=index" [formGroupName]="j">
                            <div class="remove-field-container">
                              <span class="remove-field" (click)="removeOfferField(offer, j)"> x </span>
                            </div>
                            <mat-form-field appearance="outline" class="table-select mb-2">
                              <mat-select class="text-center" formControlName="name" placeholder="parameter">
                                <mat-option [value]="name.value" *ngFor="let name of offerFieldNames">
                                  {{ name.key }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <div>
                              <mat-form-field appearance="outline" class="table-select mb-2">
                                <mat-select class="text-center" formControlName="operator" placeholder="operator">
                                  <mat-option [value]="'+'">
                                  +
                                  </mat-option>
                                  <mat-option [value]="'-'">
                                  -
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div formArrayName="arguments">
                              <div *ngFor="let argument of field.get('arguments').controls; let k = index" [formGroupName]="k">
                                <mat-form-field appearance="outline" class="table-select text-center">
                                  <input type="number" matInput formControlName="argValue" placeholder="value">
                                </mat-form-field>
                              </div>
                            </div>
                            <hr>
                          </div>
                          <div class="remove-offer-container">
                            <span 
                              *ngIf="rule.get('result.offers').length > 0 " 
                              class="remove-offer"
                              (click)="removeOffer(rule.get('result.offers'), i)"
                            >
                              x
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div  *ngIf="rule.get('result.offers').length > 0 " class="col-2 form-group d-flex mb-0">
                    <mat-slide-toggle checked="{{rule.applyToAll}}" (change)="onToggle($event)" color="primary" class="button-v2 bg-outline mr-3 my-2" formControlName="applyToAll">
                      Apply To All
                    </mat-slide-toggle>
                  </div>
              </ng-container>
            </div>

            <div class="actions px-1">
              <button mat-icon-button color="none" aria-label="Delete rule"
                (click)="removeRule(ruleSetIndex, ruleIndex)">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="32" height="32" rx="16" fill="#EB5757" fill-opacity="0.2" />
                  <path
                    d="M10.4997 22.4167C10.4997 23.425 11.3247 24.25 12.333 24.25H19.6663C20.6747 24.25 21.4997 23.425 21.4997 22.4167V11.4167H10.4997V22.4167ZM12.333 13.25H19.6663V22.4167H12.333V13.25ZM19.208 8.66667L18.2913 7.75H13.708L12.7913 8.66667H9.58301V10.5H22.4163V8.66667H19.208Z"
                    fill="#EB5757" />
                </svg>
              </button>
            </div>
            <br>
          </div>

          <div class="footer">
            <button mat-stroked-button color="none"
              class="add-condition text-uppercase text-center d-block w-25 p-2 my-3"
              (click)="showRuleTypes(ruleSetIndex)">+ Add Rule</button>
            <ng-container *ngIf="addRuleButtonsShow[ruleSetIndex]">
              <label color="none" class="text-uppercase text-center d-inline-block w-auto p-2 my-3">Select rule
                type:</label>
              <button mat-stroked-button color="none"
                class="add-condition text-uppercase text-center d-inline-block w-25 ml-1 p-2 my-3"
                (click)="addRule(ruleSet, ruleSetIndex, 'logic')">+ Add Rule(Logic)</button>
              <button mat-stroked-button color="none"
                class="add-condition text-uppercase text-center d-inline-block w-25 ml-1 p-2 my-3"
                (click)="addRule(ruleSet, ruleSetIndex, 'db')">+ Add Rule(DB)</button>
              <button mat-stroked-button color="none"
                class="add-condition text-uppercase text-center d-inline-block w-25 ml-1 p-2 my-3"
                (click)="addRule(ruleSet, ruleSetIndex, 'score')">+ Add Rule(Score)</button>
            </ng-container>

          </div>
        </div>

      </div>

    </div>

    <div *ngIf="form['controls'].length"  class="score-card-footer bg-white px-md-5">
      <button mat-raised-button color="warn" class="add-rule-set text-uppercase text-center d-block w-25 my-2"
      (click)="addRuleSet()">+ Add Rule Set</button>

      <div class="d-md-flex justify-content-end">

        <button *ngIf="ruleSetDraft" (click)="removeRuleSet(form['controls'].length-1)" mat-stroked-button color="none" class="button-v2 shadow-sm bg-outline border mr-3 my-2">
          Cancel
        </button>

        <button mat-raised-button color="accent" (click)="updateRuleSetByTenantId(form.value)" [disabled]="!form.valid || !form.touched" class="button-v2 shadow bg-orange text-white my-2">
          SAVE
        </button>
      </div><!-- Buttons-->
    </div>

  </div>
  <div *ngIf="loading" class="loader"></div>

  <div *ngIf="!form['controls'].length && !loading" class="no-rule-sets">
    <h2>
      Start by creating a rule set.
    </h2>
    <br>
    <button class="btn btn-primary text-uppercase text-center" (click)="addRuleSet()">
      + Add Rule Set
    </button>
  </div>
</div>