<form [formGroup]="form" class="fields-outline" autocomplete="off">
  <div>
    <div class="row">
      <div class="col-sm-6">
        <mat-label>Transmission Method <span *ngIf="!data.application">*</span></mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="transmissionMethod" (selectionChange)="onTransmissionMethodChanged($event)">
            <mat-option *ngFor="let row of transmissionMethodList" [value]="row.value">
              {{ row.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-6" *ngIf="(paymentReason === 'Client_Repayment' && transmissionProcessors?.length) || (paymentReason === 'Syndicator_Repayment' && (form.value.transmissionMethod === 'ACH' || form.value.transmissionMethod === 'Syndicator_Wallet'))">
        <mat-label>Transmission Processor</mat-label>
        <mat-form-field *ngIf="!recurringFee && !this.data.syndicator" appearance="outline">
          <mat-select formControlName="transmissionProcessor"
                      (selectionChange)="onTransmissionProcessorChanged()">
            <mat-option *ngFor="let row of transmissionProcessors" [value]="row" (onSelectionChange)="selectProcessor($event, row.processorAccountName)">
              <span *ngIf="row.automated">{{ row.processor }}{{ row.processorAccountName ? ' - ' + row.processorAccountName : '' }}</span>
              <span *ngIf="!row.automated">{{ row.processor }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="recurringFee || this.data.syndicator" appearance="outline">
            <mat-select formControlName="transmissionProcessor"
                        (selectionChange)="onTransmissionProcessorChanged()">
              <mat-option *ngFor="let row of transmissionProcessors" [value]="row" (onSelectionChange)="selectProcessor($event, row.processorAccountName)">
                {{ row }}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="data.type !== 'syndication'">
      <div [ngClass]="'col-sm-4'" *ngIf="!recurringFee">
        <mat-label>Amount *</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="amount" currencyMask>
        </mat-form-field>
      </div>

      <div [ngClass]="'col-sm-4'">
        <mat-label>Start Date <span *ngIf="!data.application">*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="startDate"
            [min]="automatedProcessing ? minDate : ''"
            [matDatepickerFilter]="weekendFilter"
            (dateChange)="(form.value.paymentFrequency !== 'Daily' ? selectDayOfTheWeek() : '') || (automatedProcessing && checkDate())" placeholder="01 / 01 / 2020"
            formControlName="startDate" oiqValidateDate>
          <mat-error *ngIf="!startDateValid">
            Invalid date: please select a non-holiday weekday
          </mat-error>
          <mat-datepicker-toggle matPrefix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-4" *ngIf="data.funding">
        <mat-label>End Date</mat-label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="endDate" [min]="form.get('startDate').value" placeholder="01 / 01 / 2020"
            formControlName="endDate" oiqValidateDate>
          <mat-datepicker-toggle matPrefix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-label>Payment Frequency *</mat-label>
          <mat-radio-group name="frequency" formControlName="paymentFrequency"
            (change)="onPaymentFrequencyChange($event)">
            <div>
              <mat-radio-button color="primary" [value]="'Daily'">Daily</mat-radio-button>
            </div>
            <div>
              <mat-radio-button color="primary" [value]="'Weekly'">Weekly</mat-radio-button>
            </div>
            <div *ngIf="data.type !== 'syndication'">
              <mat-radio-button color="primary" [value]="'Biweekly'">Biweekly</mat-radio-button>
            </div>
            <div>
              <mat-radio-button color="primary" [value]="'Monthly'">Monthly</mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group" formGroupName="paymentDays" *ngIf="form.value.paymentFrequency === 'Daily'">
          <mat-label>Payment Days *</mat-label>

          <div>
            <mat-checkbox color="primary" formControlName="monday" [disabled]="form.value.paymentFrequency === 'Daily'">
              Monday
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox color="primary" formControlName="tuesday" [disabled]="form.value.paymentFrequency === 'Daily'">
              Tuesday
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox color="primary" formControlName="wednesday" [disabled]="form.value.paymentFrequency === 'Daily'">
              Wednesday
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox color="primary" formControlName="thursday" [disabled]="form.value.paymentFrequency === 'Daily'">
              Thursday
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox color="primary" formControlName="friday" [disabled]="form.value.paymentFrequency === 'Daily'">
              Friday
            </mat-checkbox>
          </div>
        </div>

        <div class="form-group"
          *ngIf="form.value.paymentFrequency !== 'Daily' && form.value.paymentFrequency !== 'Monthly'">
          <mat-label>Payment Days *</mat-label>

          <mat-radio-group name="paymentDays" formControlName="paymentDay">
            <div>
              <mat-radio-button color="primary" [value]="'monday'" [disabled]="form.get('paymentDay').value !== 'monday' && data.type !== 'syndication' && !data.application">Monday</mat-radio-button>
            </div>

            <div>
              <mat-radio-button color="primary" [value]="'tuesday'" [disabled]="form.get('paymentDay').value !== 'tuesday' && data.type !== 'syndication' && !data.application">Tuesday</mat-radio-button>
            </div>

            <div>
              <mat-radio-button color="primary" [value]="'wednesday'" [disabled]="form.get('paymentDay').value !== 'wednesday' && data.type !== 'syndication' && !data.application">Wednesday</mat-radio-button>
            </div>

            <div>
              <mat-radio-button color="primary" [value]="'thursday'" [disabled]="form.get('paymentDay').value !== 'thursday' && data.type !== 'syndication' && !data.application">Thursday</mat-radio-button>
            </div>

            <div>
              <mat-radio-button color="primary" [value]="'friday'" [disabled]="form.get('paymentDay').value !== 'friday' && data.type !== 'syndication' && !data.application">Friday</mat-radio-button>
            </div>
          </mat-radio-group>
        </div>

        <div class="form-group" *ngIf="data.type === 'syndication' && form.value.paymentFrequency === 'Monthly'">
          <mat-label>Choose Payment Day *</mat-label>
          <mat-form-field appearance="outline" >
            <mat-select formControlName="paymentDay">
              <mat-option *ngFor="let day of daysOfTheMonth" [value]="day">{{ day }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="transmissionProcessors?.length && data?.funding">
      <oiq-bank-selection [type]="'client'" [id]="funding?.client?.id" [fundingBankId]="form.value.bankId"
        [singleBank]="true" (onFundingBankSelected)="onBankSelected($event)" [repaymentBankRequired]="automatedProcessing"></oiq-bank-selection>
    </div>
    <div *ngIf="form.value.transmissionMethod === 'ACH' && data?.syndicator">
      <oiq-bank-selection [type]="'syndicator'" [id]="data?.syndicator?.id" [fundingBankId]="form.value.bankId"
        [singleBank]="true" (onFundingBankSelected)="onBankSelected($event)"></oiq-bank-selection>
    </div>

    <div *ngIf="data.funding" class="row" formArrayName="notes">
      <table class="notes-table">
        <thead>
          <tr>
            <th>Note</th>
            <th class="narrow">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let note of form.get('notes')['controls']; let noteIndex = index;">
            <td [formGroup]="note">
              <span *ngIf="!editingNote[noteIndex]">{{ note.value.content }}</span>
              <mat-form-field appearance="outline" *ngIf="editingNote[noteIndex]">
                <input matInput type="text" formControlName="content">
              </mat-form-field>
            </td>
            <td class="narrow">
              <button mat-raised-button color="accent" class="btn-table me-2"
                [disabled]="editingNote[noteIndex]" (click)="editNote(noteIndex)">
                EDIT
              </button>
              <button mat-raised-button color="warn" class="btn-table me-2" (click)="deleteNote(noteIndex)">
                DELETE
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">
              <button mat-raised-button color="accent" class="btn-table me-2 float-end"
                (click)="addNote()">
                ADD NOTE
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div *ngIf="errorMessage" class="error-text mb-4">{{ errorMessage }}</div>
    <div *ngIf="walletErrorMessage !== ''" class="col-sm-12 error-text my-3">{{ walletErrorMessage }}</div>
    <ng-container *ngIf="!recurringFee">
      <mat-label class="col-sm-12" *ngIf="paymentPlan?.status === 'Active'"><strong>* Clicking on "Save" will create a new payment plan and pause this one</strong></mat-label>

      <button mat-raised-button color="accent" type="submit" [buttonDisabled]="!form.valid" (click)="savePayment()" oiq-loading>
        SAVE
      </button>
      <button mat-stroked-button (click)="close()" class="ms-2">CANCEL</button>
    </ng-container>
  </div>
</form>
