import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NotificationService, FundingService } from '@core/services';
import { ViewNotificationDialogComponent } from '@shared/view-notification-dialog/view-notification-dialog.component';

import { merge, Subscription } from "rxjs";
import { tap } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'oiq-funding-notifications-emails',
  templateUrl: './funding-notifications-emails.component.html',
  styleUrls: ['./funding-notifications-emails.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class FundingNotificationsEmailsComponent implements OnInit {
  @Input() targetType: string = 'Application';
  @Input() showTitle: boolean;
  private fundingSubscription: Subscription;

  rows: Array<any> = [];
  datasource: any;

  targetId: number;

  funding: any;
  events: Array<any> = [];


  total: number;
  currentPage: number;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  columns: string[] = ['events', 'target', 'targetId', 'medium', 'from', 'to', 'subject', 'createdDate', 'actions'];

  expandedElement: any = null;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private fundingService: FundingService
  ) { }

  ngOnInit() {
  	this.fundingSubscription = this.fundingService.funding.subscribe((funding) => {
      this.funding = funding;
      if (this.funding) {
        this.targetType === 'Funding' ? this.targetId = this.funding.id : this.targetId = this.funding.applicationId;
        this.getNotifications();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();;
          this.getNotifications();
        })
      )
      .subscribe();
  }

  toggleEventsRow(row) {
    this.expandedElement = this.expandedElement === row ? null : row;

    if (this.expandedElement) {
      this.notificationService.listEvents(row.id).subscribe((data) => {
        this.events = data;
      });
    }
  }

  viewNotification(row: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      notificationHistory: row
    }
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(ViewNotificationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getNotifications();
      }
    });

    return false;
  }

  getNotifications() {
    this.notificationService.listByCriteria(
      this.targetType,
      this.targetId,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir
    ).subscribe((r: any) => {
      this.rows = r.content;
      this.total = r.totalElements;
      this.datasource = new MatTableDataSource(this.rows);
      this.datasource.sort = this.sort;
    });
  }

}

