import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { RoleService, TenantService, WhiteLabelsService } from '@core/services';

import { User } from '@core/models';
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'oiq-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  form: FormGroup;
  whiteLabelIdsForm: FormControl;
  selectedWhiteLabels: any = [];
  user: any;
  roles = [];
  type: string;
  userRole: string;
  editUser = false;
  strongPassword = false;
  tenantSettings: any;
  minimumPasswordLength: number;
  maximumPasswordLength: number;
  whiteLabels: any = [];
  tenantId: number;
  nonAdminUser: boolean = false;
  adminRoles = ["ONYXIQ_ADMIN", "SUPER_ADMIN", "ADMIN"]

  resourceTypes: Array<any> = [
    { name: 'Borrowing Base', value: 'borrowing-base' }
  ];

  constructor(
    private tenantService: TenantService,
    private roleService: RoleService,
    public whiteLabelsService: WhiteLabelsService,
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = new User;
    this.whiteLabelIdsForm = new FormControl();

    if (this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.userRole) {
      this.userRole = this.dialogRef.componentInstance.data.userRole;
    }

    if (this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.type) {
      this.type = this.dialogRef.componentInstance.data.type;

      if (this.type === 'iso') {
        this.form.controls['loginEnabled'].setValue(false);
      }

      if (this.type === 'sales-rep') {
        this.form.controls['loginEnabled'].setValue(false);
        this.whiteLabelIdsForm.setValidators([Validators.required]);
      }

      if (this.type === 'law firm') {
        this.form.controls['loginEnabled'].setValue(false);
      }

      if (this.type === 'tenant' && this.userRole === 'SUPER_ADMIN' && this.data.user) {
        if (!this.resourceTypes.find((row) => row.value === 'edit-funding') && (this.data.user.roles[0].name === 'ADMIN' || this.data.user.roles[0].name === 'ONYXIQ_ADMIN')) {
          this.resourceTypes.push({ name: 'Edit Funding', value: 'edit-funding' });
        }
      } else {
        this.resourceTypes = this.resourceTypes.filter((row) => row.value !== 'edit-funding');
        this.form.get('resources').setValue([]);
      }
    }

    if (this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.tenantId) {
      this.tenantId = this.dialogRef.componentInstance.data.tenantId;
    }

    if (this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.user) {
      this.user = this.dialogRef.componentInstance.data.user;

      if (this.user.resources) {
        this.user.resources = this.user.resources.map((row) => {
          return row.resource;
        });
      }

      const whiteLabelIds = this.user.whiteLabels.map(whiteLabel => {
        return whiteLabel.id;
      });

      this.whiteLabelIdsForm.setValue(whiteLabelIds);

      this.form.patchValue(this.user);

      this.validateMfaDestination();

      if (this.user.id) {
        this.editUser = true;
      }
    }

    this.getRoles();

    if (this.tenantId) {
      this.getTenantSettings();
    }

    if (this.data.action === 'newApp') {
      this.form.get('loginEnabled').setValue(false);
    }

    this.form.get('cellNumber').valueChanges.subscribe(val => {

      if (!this.form.get('cellNumber').valid || !this.form.get('cellNumber').value) {
        if (this.form.get('mfaDestination').value !== 'Email') {
          this.form.get('mfaDestination').setValue(null);
        }
      }
    });

    this.form.get('mfaEnabled').valueChanges.subscribe(val => {
      this.validateMfaDestination();
    });

    this.form.get('loginEnabled').valueChanges.subscribe(val => {
      if (!val) {
        this.form.get('password').disable();
        this.form.get('passwordConfirm').disable();
        this.form.get('mfaEnabled').disable();
        this.form.get('mfaDestination').disable();
      } else {
        this.form.get('password').enable();
        this.form.get('passwordConfirm').enable();
        this.form.get('mfaEnabled').enable();
        this.form.get('mfaDestination').enable();
      }
    });
    this.getWhiteLabels();
    this.assignableWhiteLabels();
  }

  getWhiteLabels() {
    if (this.data.type === 'syndicator' || this.data.type === 'iso' || this.data.type === 'law firm' || this.data.type === 'sales-rep') {
      this.whiteLabelsService.list(this.data.tenantId).subscribe(whiteLabels => {
        this.whiteLabels.content = this.data.selectedWhiteLabels.filter((whiteLabel: any) => whiteLabels.content.some(wl => wl.id === whiteLabel.id));
      });
    } else {
      this.whiteLabelsService.list(this.data.tenantId).subscribe(whiteLabels => {
        this.whiteLabels = whiteLabels;
      });
    }
  }

  getTenantSettings() {
    this.tenantService.getSettings(this.tenantId)
      .subscribe((r: any) => {
        this.tenantSettings = r;
        this.minimumPasswordLength = r.minimumPasswordLength;
        this.maximumPasswordLength = r.maximumPasswordLength;
        if (r.mfaEnforced) {
          this.form.get('mfaEnabled').setValue(true);
          this.form.get('mfaEnabled').disable();
          this.form.get('mfaDestination').setValidators([Validators.required]);
        }
      });
  }

  validateMfaDestination() {
    if (this.form.get('mfaEnabled').value) {
      this.form.get('mfaDestination').setValidators([Validators.required]);
    } else {
      this.form.get('mfaDestination').clearValidators();
    }
    this.form.get('mfaDestination').updateValueAndValidity();
  }

  getRoles() {
    if (this.type !== 'iso' && this.type !== 'syndicator' && this.type !== 'law firm' && this.type !== 'sales-rep') {
      this.roleService.getAllRoles()
        .subscribe((r: any) => {
          this.roles = this.userRole === 'SUPER_ADMIN' ? r.content : r.content.filter(role => role.name !== 'ONYXIQ_ADMIN');

          this.editUser ? this.form.controls['roleIds'].setValue(this.data.user.roles[0].id) :
            this.dialogRef.componentInstance.data.user ? this.form.controls['roleIds'].setValue(this.dialogRef.componentInstance.data.user.roleIds[0]) : '';
        });
    } else {
      this.roleService.list()
        .subscribe((r: any) => {
          this.roles = this.userRole === 'SUPER_ADMIN' ? r.content : r.content.filter(role => role.name !== 'ONYXIQ_ADMIN');

          // temporary workaround until role management model is finalized
          this.editUser ? this.form.controls['roleIds'].setValue(this.dialogRef.componentInstance.data.user.roles[0].id) :
            this.dialogRef.componentInstance.data.user ? this.form.controls['roleIds'].setValue(this.dialogRef.componentInstance.data.user.roleIds[0]) : '';

          if (this.type !== 'tenant') {
            const roles = this.roles.filter((row) => row.name.toLowerCase() === this.type.toLowerCase());

            if (!this.editUser && roles.length > 0) {
              this.form.controls['roleIds'].setValue(roles[0].id);
            }
          }
          if (this.type === 'sales-rep') {
            const roles = this.roles.filter((row) => row.name.toLowerCase() === 'iso');

            if (!this.editUser && roles.length > 0) {
              this.form.controls['roleIds'].setValue(roles[0].id);
            }
          }
        });
    }
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  onSubmit() {
    if (this.form.valid) {
      const whiteLabels = {
        whiteLabelIds: this.whiteLabelIdsForm.value
      }
      this.dialogRef.close({
        user: this.form.value,
        whiteLabelList: whiteLabels
      });
    }
  }

  onWhiteLabelSelectionChange($event: MatSelectChange) {
    this.selectedWhiteLabels = $event.value;
  }

  userTypeChange() {
    if ([1,2,16].includes(this.form.get('roleIds').value)) {
      this.nonAdminUser = false;
      this.whiteLabelIdsForm.setValue([]);
    } else {
      this.nonAdminUser = true;
    }
    if ([2,16].includes(this.form.get('roleIds').value) && this.userRole === 'SUPER_ADMIN'){
      if (!this.resourceTypes.find((row) => row.value === 'edit-funding')) {
        this.resourceTypes.push({ name: 'Edit Funding', value: 'edit-funding' });
      }
    } else {
      this.resourceTypes = this.resourceTypes.filter((row) => row.value !== 'edit-funding');
      this.form.get('resources').setValue([]);
    }
  }

  assignableWhiteLabels() {
    if (this.data.user) {
      this.adminRoles.includes(this.data.user.roles[0].name) ? this.nonAdminUser = false : this.nonAdminUser = true;
    } else if (this.data.type === 'syndicator' || this.data.type === 'iso' || this.data.type === 'law firm' || this.data.type === 'sales-rep') {
      this.nonAdminUser = true;
    }
  }
}
