import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TenantService } from '@core/services';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureEnabledGuard implements CanActivate {

  constructor(private readonly tenantService: TenantService, private readonly cookieService: CookieService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tenantId = JSON.parse(this.cookieService.get('currentUser')).tenantId;
    return this.tenantService.getSettings(tenantId)
      .pipe(
        map((response: any) => {
          if(response.features.Scorecard) {
            return true;
          } else {
            this.router.navigate(['/home']);
          }
        })
      )
  }
  
}
