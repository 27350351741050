<div class="clearfix">
  <div class="float-end action-buttons mb-3">
    <button mat-stroked-button [disabled]="!application?.appliedFundingOffer" (click)="openFundingSyndicationDialog()">ADD SYNDICATION</button>
    <button mat-stroked-button [disabled]="!application?.appliedFundingOffer" (click)="openSyndicationOfferDialog()">ADD SYNDICATION OFFER</button>
  </div>
</div>

<table mat-table [dataSource]="datasource" matSort matSortDirection="desc">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">
      <span class="emphasis text-nowrap">{{ row.syndicator.name }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let row">
      <span class="emphasis">{{ row.type | status }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">
      <span class="emphasis">{{ row.status | status }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="fundedAmount">
    <th mat-header-cell *matHeaderCellDef>Funded Amount</th>
    <td mat-cell *matCellDef="let row">{{ row.fundedAmount | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="minimumPercent">
    <th mat-header-cell *matHeaderCellDef>Min&nbsp;%</th>
    <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.minimumPercentSyndicated) ? row.minimumPercentSyndicated + '%' : 'n/a' }}</td>
  </ng-container>
  <ng-container matColumnDef="maximumPercent">
    <th mat-header-cell *matHeaderCellDef>Max&nbsp;%</th>
    <td mat-cell *matCellDef="let row">
      <div class="d-inline-flex">
        {{ !isNullOrUndefined(row.maximumPercentSyndicated)  ? row.maximumPercentSyndicated + '%' : 'n/a' }}
        <i *ngIf="row.maximumPercentSyndicated && row.maximumPercentSyndicated > 100"
           class="material-icons syndication-percent-error"
           [matTooltip]="'Max % cannot be greater than 100%!'">warning</i>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="acceptedAmount">
    <th mat-header-cell *matHeaderCellDef>Accepted Amount</th>
    <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.acceptedAmountSyndicated) ? (row.acceptedAmountSyndicated | currency) : 'n/a' }}</td>
  </ng-container>
  <ng-container matColumnDef="acceptedPercent">
    <th mat-header-cell *matHeaderCellDef>Accepted&nbsp;%</th>
    <td mat-cell *matCellDef="let row">
      <div class="d-inline-flex">
      {{ !isNullOrUndefined(row.acceptedPercentSyndicated ) ? row.acceptedPercentSyndicated + '%' : 'n/a'}}
      <i *ngIf="row.acceptedPercentSyndicated && row.acceptedPercentSyndicated > 100"
         class="material-icons syndication-percent-error"
         [matTooltip]="'Accepted % cannot be greater than 100%!'">warning</i>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="fee">
    <th mat-header-cell *matHeaderCellDef>Management Fee %</th>
    <td mat-cell *matCellDef="let row">{{ row.managementFeePercent }}%</td>
  </ng-container>
  <ng-container matColumnDef="commission">
    <th mat-header-cell *matHeaderCellDef>Commission&nbsp;%</th>
    <td mat-cell *matCellDef="let row">{{ row.commissionPercent }}%</td>
  </ng-container>
  <ng-container matColumnDef="commissionAmount">
    <th mat-header-cell *matHeaderCellDef>Commission</th>
    <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.maximumCommissionAmount) ? (row.maximumCommissionAmount | currency) : 'n/a' }}</td>
  </ng-container>
  <ng-container matColumnDef="maxTotalReceived">
    <th mat-header-cell *matHeaderCellDef>Total to be Syndicated</th>
    <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.maximumTotalReceived ) ? (row.maximumTotalReceived | currency) : 'n/a' }}</td>
  </ng-container>
  <ng-container matColumnDef="maxPayback">
    <th mat-header-cell *matHeaderCellDef>Payback Amount</th>
    <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.maximumPaybackAmount) ? (row.maximumPaybackAmount | currency) : 'n/a' }}</td>
  </ng-container>
  <ng-container matColumnDef="term">
    <th mat-header-cell *matHeaderCellDef>Term Length</th>
    <td mat-cell *matCellDef="let row">{{ row.termLength }}</td>
  </ng-container>
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>Syndication Details</th>
    <td mat-cell *matCellDef="let row">
      <button mat-raised-button color="warn" class="btn-table" type="button"
        (click)="resendOfferEmail(row)">
        SEND EMAIL
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="text-end">
      <div class="d-flex justify-content-end flex-shrink-0" *ngIf="!row.deleting" >
        <button [disabled]="row.acceptedPercentSyndicated > 100 || row.maximumPercentSyndicated > 100" mat-stroked-button class="btn-table me-2" (click)="openPaymentDialog(row)" *ngIf="row.status === 'Pending_Payment'">PAYMENTS</button>
        <button mat-stroked-button class="btn-table me-2" (click)="openEditSyndicationOfferDialog(row)" *ngIf="row.status ==='Pending_Approval'" >EDIT</button>
        <button mat-raised-button color="warn" class="btn-table" type="button" (click)="deleteOffer(row)" [disabled]="!row?.deletable" [matTooltip]="!row?.deletable ? 'This syndication offer cannot be deleted because a repayment has already been processed.' : ''">DELETE </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0"></oiq-loading-message>
<mat-paginator [length]="total" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
