import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyndicatorService } from '@core/services';

@Component({
  selector: 'oiq-add-syndicator-wallet',
  templateUrl: './add-wallet.component.html',
  styleUrls: ['./add-wallet.component.scss']
})
export class AddSyndicatorWalletComponent implements OnInit {

  form: FormGroup;
  loading = false;
  wallet: any;
  errorMessage: string | any[];

  constructor(
    private fb: FormBuilder,
    private syndicatorService: SyndicatorService,
    public dialogRef: MatDialogRef<AddSyndicatorWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.form = this.fb.group({
      whiteLabelId: ['', Validators.required],
      balance: [0, Validators.required],
      pendingCredits: [0, Validators.required],
      pendingDebits: [0, Validators.required],
      availableBalance: [0, Validators.required],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      this.syndicatorService.addSyndicatorWallet(this.data.syndicatorId, this.form.value)
        .subscribe((r: any) => {
          this.wallet = r;
        },
          (error) => {
            this.loading = false;
            this.errorMessage = error;
            // this.dialogRef.close();
          },
          () => {
            this.loading = false;
            this.dialogRef.close(this.wallet);
          }
        );
    }
  }

}
