import { FormControl, FormGroup, FormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

import { Address, addressValidator } from './address';
import { Client } from './client';

export class Contract extends FormGroup {
  constructor() {
    super({
      client: new Client(),
      expectedStartDate: new FormControl(null, Validators.required),
      paymentFrequency: new FormControl(null),
      paymentDay: new FormControl(null),
      paymentDays: new PaymentDays(),
      addressId: new FormControl(null),
      clientAddress: new Address(),
      owners: new FormArray([]),
      crossCollateralizations: new FormArray([]),
      agreementDate: new FormControl('', Validators.required),
      paymentMethod: new FormControl('ACH', Validators.required),
      paymentProcessor: new FormControl(),
      paymentBankId: new FormControl('', Validators.required),
      paymentBankAddress: new Address(),
      mailingAddressId: new FormControl(null),
      mailingAddress: new Address(),
    });
  }
}

export class PaymentDays extends FormGroup {
  constructor() {
    super({
      monday: new FormControl(false),
      tuesday: new FormControl(false),
      wednesday: new FormControl(false),
      thursday: new FormControl(false),
      friday: new FormControl(false)
    })
  }
}

export class CrossCollateralization extends FormGroup {
  constructor() {
    super({
      name: new FormControl('', Validators.required),
      address: new FormControl(),
      ein: new FormControl()
    });
  }
}

export class ContractOwner extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      contactId: new FormControl(),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
      cellNumber: new FormControl(),
      workNumber: new FormControl(),
      faxNumber: new FormControl(),
      homeNumber: new FormControl(),
      addressId: new FormControl(),
      address: new Address(),
      addresses: new FormArray([new Address()]),
      ssn: new FormControl('', Validators.required),
      fico: new FormControl(),
      dob: new FormControl(),
      driversLicense: new FormControl(),
      title: new FormControl(),
      include: new FormControl(false),
      primary: new FormControl(false),
      editing: new FormControl(false),
      updateContactRecord: new FormControl(false)
    });
  }
}

export class ResendContract extends FormGroup {
  constructor() {
    super({
      cancelExisting: new FormControl(false, Validators.required),
      signers: new FormArray([])
    });
  }
}

export function ownerInfoValidator(control: AbstractControl): ValidationErrors {
  const formGroup = control as FormGroup;

  if (!formGroup.get('firstName').value || formGroup.get('firstName').value === '') {
    return {
      invalidLastName: true
    };
  }

  if (!formGroup.get('lastName').value || formGroup.get('lastName').value === '') {
    return {
      invalidLastName: true
    };
  }

  if (!formGroup.get('email').value || formGroup.get('email').value === '') {
    return {
      invalidEmail: true
    };
  }

  if (!formGroup.get('ssn').value || formGroup.get('ssn').value === '') {
    return {
      invalidSsn: true
    };
  }

  return null;
}
