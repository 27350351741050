import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'users/';
  }

  listByCriteria(roles: Array<string>, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (roles && roles.length > 0) { params['roles'] = roles.join(','); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  disableUser(userId: number) {
    return this.http.put(this.getUrl() + `${userId}/disable`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  enableUser(userId: number) {
    return this.http.put(this.getUrl() + `${userId}/enable`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  saveUser(data: any, tenantId?: number, userId?: number, roleIds?: any) {
    if (tenantId) { data['tenantId'] = tenantId; }
    if (roleIds && roleIds.length > 0) {
      data['roleIds'] = roleIds;
    }
    if (!userId) {
      return this.http.post(this.getUrl(), data)
        .pipe(map(res => {
          return res;
        }));
    } else {
      return this.http.put(this.getUrl() + userId, data)
        .pipe(map(res => {
          return res;
        }));
    }
  }

  assignUserWhiteLabel(whiteLabelList, userId) {
    return this.http.put(this.getUrl() + `${userId}/whitelabels`, whiteLabelList)
      .pipe(map(res => {
        return res;
      }));

  }

  public getUsersByRoles(roles: string[], whiteLabelIds?: []): Observable<any[]> {
    const params = {};
    if (whiteLabelIds) {
      params['whiteLabelIds'] = whiteLabelIds.join(',');
    }
    return this.http.get<any[]>(`${this.getUrl()}by-roles?roles=${roles}`, { params: params });
  }

}
