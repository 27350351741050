import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { SyndicatorService } from '@core/services';
import { AddManualWalletTransactionComponent } from './add-manual-wallet-transaction/add-manual-wallet-transaction.component';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip, CategoryScale } from 'chart.js';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '@core/authentication/auth.service';

Chart.register(LineController, Legend, LineElement, PointElement, Legend, LinearScale, Tooltip, Title, CategoryScale
);

@Component({
  selector: 'oiq-view-syndicator-wallet',
  templateUrl: './view-syndicator-wallet.component.html',
  styleUrls: ['./view-syndicator-wallet.component.scss']
})
export class ViewSyndicatorWalletComponent implements OnInit {

  id: number;
  walletId: number;
  syndicator: any;
  wallet: any = [];
  transmissions: any = [];
  transmissionsTable: any;

  total: number;
  currentPage: number = 0;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  infoBoxes: {
    startDate: string;
    endDate: string;
    historyRange: number;
    activeRTR: any;
    totalRTR: any;
    transactionsHistory: {
      syndicatorPayments: { count: any; total: any; };
      syndicatorRepayments: { count: any; total: any; };
      walletCredit: { count: any; total: any; };
      walletDebit: { count: any; total: any; };
    };
    balanceHistory: any[];
  };

  walletStatistics: {
    startDate: string;
    endDate: string;
    activeRemainingRTR: number;
    totalRemainingRTR: number;
    transactionsHistory: {
      syndicatorPayments: { count: number; total: number; };
      syndicatorRepayments: { count: number; total: number; };
      walletCredit: { count: number; total: number; };
      walletDebit: { count: number; total: number; };
    };
    balanceHistory: any[];
  };

  balancesCanvas: any;
  balancesCtx: any;
  balancesData: any;

  isSyndicatorUser: boolean;
  balancesGraph: Chart<"line", any[], string>;
  backToWalletsLink: string;

  cumulativeBalances = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private syndicatorService: SyndicatorService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }

  // reasons: Syndicator_Payment, Syndicator_Repayment, Wallet_Credit, Wallet_Debit
  // statuses: Scheduled, Submitted, Settled, Returned, Rejected, Failed

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('balancesChart') balancesChart;

  selection = new SelectionModel(true, []);
  displayedColumns: string[] = ['id', 'method', 'reason', 'createdDate', 'hitDate', 'settlementDate', 'returnDate', 'balanceBefore', 'amount', 'balance', 'status', 'responseDescription', 'updatedDate', 'createdBy', 'updatedBy'];

  ngOnInit() {
    this.getIsSyndicatorUser();
    this.id = this.isSyndicatorUser ? this.authService.getUser().syndicatorIds[0] : this.activatedRoute.snapshot.params['id'];
    this.walletId = this.activatedRoute.snapshot.params['walletId'];
    this.setBackToWalletsLink();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.currentPage) {
        this.currentPage = params.currentPage;
      } else {
        this.currentPage = 0;
      }

      if (params.pageSize) {
        this.pageSize = params.pageSize;
      }

      if (params.sortBy) {
        this.sortBy = params.sortBy;
      }

      if (params.sortDir) {
        this.sortDir = params.sortDir;
      }
      this.getWallet();
    });
  }

  getIsSyndicatorUser() {
    this.isSyndicatorUser = this.authService.getUser().roles.filter((role) => role.name === 'SYNDICATOR').length > 0;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();
          this.updateQueryParams();
        })
      )
      .subscribe();
  }

  updateQueryParams() {
    const queryParams: any = {};

    if (this.currentPage) {
      queryParams.currentPage = this.currentPage;
    }

    if (this.pageSize) {
      queryParams.pageSize = this.pageSize;
    }

    if (this.sortBy) {
      queryParams.sortBy = this.sortBy;
    }

    if (this.sortDir) {
      queryParams.sortDir = this.sortDir;
    }

    this.router.navigate([], { queryParams });
  }

  getWallet() {
    this.syndicatorService.getSyndicatorWalletById(this.id, this.walletId)
      .subscribe((r: any) => {
        this.wallet = r;
        if (r) {
          this.getWalletStatistics();
          this.getWalletTransactions();
        }
      }, (error) => {
        this.router.navigate(['home']);
      });
  }

  getWalletTransactions(): void {
    this.syndicatorService.getSyndicatorWalletTransmissions(
      this.id,
      this.walletId,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir
    )
      .subscribe((r: any) => {
        this.transmissions = r.content;
        this.total = r.totalElements;
        this.transmissionsTable = new MatTableDataSource(this.transmissions);
        this.transmissionsTable.sort = this.sort;
      });
  }

  getWalletStatistics(): void {
    this.syndicatorService.getSyndicatorWalletStatistics(this.id, this.walletId)
      .subscribe((r: any) => {
        this.walletStatistics = r;
        this.loadBlanceHistoryChart('statistics');
      });
  }

  addWalletTransaction(paymentReason: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '750px';
    dialogConfig.data = {
      wallet: this.wallet,
      syndicatorId: this.syndicator.id,
      paymentReason: paymentReason,
      whiteLabelId: this.wallet.whiteLabelId,
    };
    const dialogRef = this.dialog.open(AddManualWalletTransactionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getWallet();
      }
    });
  }

  // method to print line chart using Chart.js
  loadBlanceHistoryChart(section: string): void {

    this.balancesCanvas = this.balancesChart.nativeElement;
    this.balancesCtx = this.balancesCanvas.getContext('2d');

    if (this.balancesGraph) {
      this.balancesGraph.destroy();
    }

    const balancesData = Object.values(this.walletStatistics.balanceHistory);
    const datasetLabel = 'Net Monthly Transactions';
    const formattedLabels = this.formatLabels(Object.keys(this.walletStatistics.balanceHistory));

    this.balancesGraph = new Chart(this.balancesCtx,  {
      type: 'line',
      data: {
        labels: formattedLabels,
        datasets: [{
          label: datasetLabel,
          data: balancesData,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            onClick: null // Disable legend click interaction
          },
        }}
    });

  }

  formatLabels(dates: string[]): string[] {
    return dates.map(date => {
      const [year, month] = date.split('-');
      const dateObj = new Date(Number(year), Number(month) - 1);
      return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
    });
  }

  calculateCumulativeBalances(): void {
    let cumulativeBalance = 0;
    const cumulativeBalances: { [key: string]: number } = {};

    Object.keys(this.walletStatistics.balanceHistory).forEach(date => {
      cumulativeBalance += this.walletStatistics.balanceHistory[date];
      cumulativeBalances[date] = cumulativeBalance;
    });

    this.cumulativeBalances = cumulativeBalances;
  }

  setBackToWalletsLink() {
    if (this.isSyndicatorUser) {
      this.backToWalletsLink = '/home/syndicator-wallets';
    } else {
      this.backToWalletsLink = `/syndicators/${this.id}/wallets`;
    }
  }

}
