import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './core/authentication/auth.guard';
import { AuthService } from './core/authentication/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';

import { BorrowingBaseMainComponent } from './borrowing-base/borrowing-base-main.component';
import { BorrowingBaseComponent } from './borrowing-base/view/borrowing-base.component';
import { BBReportsComponent } from './borrowing-base/bb-reports/bb-reports.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { CookieService } from 'ngx-cookie-service';
import { CountdownModule } from 'ngx-countdown';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HttpIntercept } from './core/http.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorCustom } from '@shared/custom-paginator';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ExemptionDialogComponent } from './borrowing-base/exemption-dialog/exemption-dialog.component';

import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { CalendarEventToast } from './shared/navigation/calendar-events/calendar-event-toast/calendar-events-toast.component';
import { PlaidLinkComponentComponent } from './plaid-link-component/plaid-link-component.component';
import { ScoreCardMainComponent } from './score-card/score-card-main.component';
import { ScoreCardComponent } from './score-card/view/score-card.component';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'api/attachments/upload',
  maxFilesize: 50,
  timeout: 0
};

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    BorrowingBaseMainComponent,
    BorrowingBaseComponent,
    BBReportsComponent,
    ExemptionDialogComponent,
    CalendarEventToast,
    PlaidLinkComponentComponent,
    ScoreCardMainComponent,
    ScoreCardComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MaterialModule,
    DropzoneModule,
    SharedModule,
    NgxSliderModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgbTypeaheadModule,
    FormsModule,
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: CalendarEventToast, // added custom toast!
      preventDuplicates: true,
    }),
    CountdownModule
  ],
  providers: [
    AuthGuard,
    Title,
    AuthService,
    CookieService,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercept,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorCustom
    }
  ],
  entryComponents: [
    ExemptionDialogComponent,
    CalendarEventToast,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
