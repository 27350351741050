import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService, ScoreCardService } from '@core/services';

@Component({
  selector: 'oiq-score-card-decision-modal',
  templateUrl: './score-card-decision-modal.component.html',
  styleUrls: ['./score-card-decision-modal.component.scss']
})
export class ScoreCardDecisionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ScoreCardDecisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  private applicationService: ApplicationService, private scoreCardService: ScoreCardService) { }

  public applications: any[];
  public applicationId;

  ngOnInit() {
    this.applicationService.listByCriteria(
      '',
      '',
      null,
      null,
      null,
      null,
      null,
      '',
      '',
     null,
      'Funded',
      '',
      [],
      [],
      '',
      ''
    ).subscribe(response => {
      this.applications = response.content
    })
  }

  onSelectionChange(data: any) {
    this.applicationId = data.value;
  }

  close(data?: any): void {
    this.dialogRef.close(this.data?.DecisionResults);
  }

  runScoreCard() {
    this.scoreCardService.triggerEvent(this.applicationId)
  }

}
