<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Add {{ data.paymentReason | status }}</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-6">
          <mat-label>Transmission Method *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="transmissionMethod" (selectionChange)="onTransmissionMethodChanged($event)">
              <mat-option *ngFor="let row of transmissionMethodList" [value]="row.value">
                {{ row.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-6" *ngIf="transmissionProcessors?.length">
          <mat-label>Transmission Processor</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="transmissionProcessor" (selectionChange)="onTransmissionProcessorChanged($event)">
              <mat-option *ngFor="let row of transmissionProcessors" [value]="row">
                {{ row }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-label>Amount *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="amount" currencyMask>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-label>Hit Date *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="hitDate"
                   [min]="processorType === 'Automatic' ? minDate : ''"
                   [max]="processorType !== 'Automatic' ? maxDate : ''"
                   placeholder="01 / 01 / 2020"
                   formControlName="hitDate">
            <mat-error *ngIf="!form.get('hitDate').valid">
              Invalid date: please select a non-holiday weekday
            </mat-error>

            <mat-datepicker-toggle matPrefix [for]="hitDate"></mat-datepicker-toggle>
            <mat-datepicker #hitDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-sm-4" *ngIf="processorType === 'Manual' && this.form.get('hitDate').value">
          <mat-label>Settlement Date <span>*</span></mat-label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="settlementDate"
                   placeholder="01 / 01 / 2020"
                   formControlName="settlementDate"
                   [min]="this.form.get('hitDate').value"
                   [readonly]="this.form.get('transmissionMethod').value === 'Wire_Transfer'">
            <mat-datepicker-toggle matPrefix [for]="settlementDate" [disabled]="this.form.get('transmissionMethod').value === 'Wire_Transfer'"></mat-datepicker-toggle>
            <mat-datepicker #settlementDate></mat-datepicker>
          </mat-form-field>
          <mat-error class="error-text mt-0" *ngIf="data.payment?.id && processorType === 'Automatic' && !form.get('settlementDate').valid">
            Change Hit Date to get new Settlement Date
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mt-1">
          <oiq-bank-selection
            [type]="'syndicator'"
            [id]="data?.syndicatorId"
            [fundingBankId]="form.value.bankId"
            [singleBank]="true"
            (onFundingBankSelected)="onBankSelected($event)"
            *ngIf="transmissionProcessors?.length"></oiq-bank-selection>
        </div>
      </div>

      <div class="row" *ngIf="errorMessage">
          <p class="error-text col">
            {{errorMessage}}
          </p>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="onCancel()" oiq-loading [spinner]="false">Cancel</button>
      <button mat-raised-button color="accent" type="submit" oiq-loading  [buttonDisabled]="!form.valid" (click)="onSubmit()" cdkFocusInitial>
        Add
      </button>
    </div>
  </form>
</div>
