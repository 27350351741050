import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oiq-score-card-main',
  templateUrl: './score-card-main.component.html',
})
export class ScoreCardMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
