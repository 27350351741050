<div class="body body-padding">
  <div *ngIf="showTitle">
    <h3 class="text-uppercase mx-2 mb-2"><span *ngIf="targetType === 'Application'">Funding </span>{{ targetType }}</h3>
  </div>
  <table mat-table [dataSource]="datasource" matSort matSortDirection="desc" multiTemplateDataRows matSortDisableClear>
    <ng-container matColumnDef="events">
      <th mat-header-cell *matHeaderCellDef>Events</th>
      <td mat-cell *matCellDef="let row">
        <div class="expand-cell" (click)="toggleEventsRow(row)">
          <i class="material-icons" *ngIf="row !== expandedElement">arrow_right</i>
          <i class="material-icons" *ngIf="row === expandedElement">arrow_drop_down</i>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef>Target</th>
      <td mat-cell *matCellDef="let row">{{ row.targetType }}</td>
    </ng-container>
    <ng-container matColumnDef="targetId">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let row">{{ row.targetId }}</td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columns.length">
        <div class="row-details" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
          <table class="inner-table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Reason</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let event of events">
                <td>{{ event.type | titlecase }}</td>
                <td>{{ event.details }}</td>
                <td>{{ event.createdDate | date : 'medium' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="medium">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Medium</th>
      <td mat-cell *matCellDef="let row">{{ row.medium }}</td>
    </ng-container>
    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
      <td mat-cell *matCellDef="let row">{{ row.from }}</td>
    </ng-container>
    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
      <td mat-cell *matCellDef="let row">{{ row.to }}</td>
    </ng-container>
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
      <td mat-cell *matCellDef="let row">{{ row.subject || 'n/a' }}</td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent Date</th>
      <td mat-cell *matCellDef="let row">{{ row.createdDate | date: 'medium' }}</td>
    </ng-container>
    <ng-container matColumnDef="lawFirm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Law Firm</th>
      <td mat-cell *matCellDef="let row">{{ row.lawFirm && row.lawFirm.name ? row.lawFirm.name : 'n/a' }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-end">
        <button mat-stroked-button class="btn-table me-2" (click)="viewNotification(row)">VIEW</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" class="element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
  <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0">
  </oiq-loading-message>
  <mat-paginator [length]="total" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
